.footer {
  font-size: 20px;
  color: #002147 !important;
}
.footer h4 {
  font-size: 24px;
  font-weight: bold;
  color: #002147 !important;
}
.footer .address,
.mail,
.phone {
  font-style: italic;
}

.footer .form-group .form-control {
  padding: 10px 18px 10px 18px;
  color: #002147 !important;
  /* border-color: rgb(255 255 255 / 0%) !important; */
}

/* Footer charity */

.footer-charity {
  font-size: 18px;
  /*color: #fff !important;*/
  padding: 50px 0 0;
}
.footer-charity p {
  font-size: 18px;
  /*color: #fff !important;*/
}
.footer ul li {
  display: block;
  padding: 12px 0;
}
.footer-charity ul li a {
  color: rgb(0 33 71) !important;
}

.footer-crowd {
  background: #f7f7f7;
  font-size: 18px;
  padding: 90px 0 60px 0;
  border-top: 1px solid rgb(42 42 42 / 10%);
}
.footer-crowd p {
  font-size: 18px;
}
/* .footer .social-icon .btn {
  background: #fff;
} */
.footerText {
  font-size: 14px;
}

/* Footer crowd */

.footer-contact {
  font-size: 18px;
  padding-bottom: 10px;
}

.btn.btn-icon:not(.btn-footer) i.fab {
  position: absolute;
  font-size: 2rem;
  top: 50%;
  left: 12px;
  transform: translate(-12px, -12px);
  line-height: 1.5626rem;
}

@media screen and (max-width: 400px) {
  .footer h4 {
    margin-top: 30px;
  }
}
