.bg-white .btn-border {
  background: none;
  color: #888 !important;
  border: 1px solid grey;
}

.btn-border {
  background: none;
  color: #fff !important;
  border: 1px solid white;
}

.navbar {
  padding: 1rem 0;
}
.navbar a,
.navbar p,
a.nav-link {
  font-size: 15px;
  color: #2a2a2a;
  font-weight: 600;
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
  color: #2a2a2a;
  font-weight: 600;
}
.navbar .navbar-nav .nav-link:not(.btn) {
  font-size: 15px;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.dropdown-menu .dropdown-item,
.bootstrap-select .dropdown-menu.inner li a {
  font-size: 1em;
}

@media screen and (max-width: 991px) {
  .sidebar-collapse
    .navbar-collapse
    .navbar-nav:not(.navbar-logo)
    .nav-link:not(.btn) {
    color: #fff !important;
  }
  .navbar a,
  .navbar p,
  a.nav-link {
    font-size: 15px;
    color: #ffffff;
    font-weight: 600;
  }
  .sidebar-collapse .navbar-collapse:before {
    background: #000;
  }
}
